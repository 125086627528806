import React, { ReactElement } from 'react';
import styled from 'styled-components';

interface CheckboxInputProps extends React.HTMLProps<HTMLInputElement> {
    error?: string;
    touched?: boolean;
    labelElement?: ReactElement;
    marginBottom?: string | number;
}

const CheckboxError = styled.div((props) => ({
    fontFamily: props.theme.fonts.default,
    textAlign: 'left',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '24px',
    color: '#ff0000',
    boxSizing: 'border-box',
    height: 30,
}));

const Checkmark = styled.span<{ checked?: boolean }>((props) => ({
    position: 'absolute',
    top: 10,
    left: 0,
    height: 24,
    width: 24,
    border: `1px solid ${props.theme.colors.primaryActive}`,
    boxSizing: 'border-box',
    borderRadius: 4,
    background: '#ffffff',
    textAlign: 'center',
    '&::after': {
        content: '"✓"',
        background: props.theme.colors.primary,
        border: `1px solid ${props.theme.colors.primary}`,
        borderRadius: 4,
        width: 25,
        height: 25,
        left: -1,
        top: -1,
        position: 'absolute',
        color: '#ffffff',
        fontSize: 22,
        lineHeight: '24px',
        flexShrink: 0,
        display: props.checked ? '' : 'none',
    },
}));

const CheckboxInput = styled.input({
    position: 'absolute',
    opacity: 0,
    height: 0,
    width: 0,
});

const CheckboxLabel = styled.label({
    position: 'relative',
    display: 'block',
    width: '100%',
    cursor: 'pointer',
    userSelect: 'none',
    fontSize: 12,
    lineHeight: '14px',
});

const CheckboxWrapper = styled.div<{ displayError?: boolean; marginBottom?: number | string }>(
    (props) => {
        let mb = props.marginBottom || 30;
        return {
            marginBottom: props.displayError ? 0 : mb,
        };
    }
);

export const Checkbox: React.FC<CheckboxInputProps> = ({
    id,
    checked,
    onChange,
    onBlur,
    error,
    touched,
    name,
    label,
    labelElement,
    marginBottom,
}) => {
    const displayError = touched && !!error;
    return (
        <CheckboxWrapper displayError={displayError} marginBottom={marginBottom}>
            <CheckboxLabel>
                {labelElement || <div>{label}</div>}
                <CheckboxInput
                    id={id}
                    type="checkbox"
                    checked={checked}
                    onChange={onChange}
                    onBlur={onBlur}
                    name={name}
                />
                <Checkmark checked={checked} data-test="checkbox" />
            </CheckboxLabel>
            {displayError && <CheckboxError data-test="checkbox-error">{error}</CheckboxError>}
        </CheckboxWrapper>
    );
};
