import { useUserState } from 'components/chat/hooks/UseGlobalState';
import { ActivationError } from 'components/common/ActivationError';
import Avatar from 'components/common/Avatar';
import { Checkbox } from 'components/common/Checkbox';
import Logo from 'components/common/Logo';
import PageLayout from 'components/common/PageLayout';
import { TermsLabel } from 'components/common/TermsLabel';
import { TextInput } from 'components/common/TextInput';
import { FacebookButton, FormButton } from 'components/molecules/button';
import { GoogleButton } from 'components/molecules/button/GoogleButton';
import { useFormik } from 'formik';
import isWebview from 'is-ua-webview';
import { api } from 'lib/api';
import { GTM_EVENTS } from 'lib/gtmEvents';
import { getQueryParams } from 'lib/queryParams';
import { useStore } from 'lib/store';
import { openLink, useLogin } from 'lib/utils';
import getConfig from 'next/config';
import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';
import { getUA, isIOS } from 'react-device-detect';
import TagManager from 'react-gtm-module';
import styled, { useTheme } from 'styled-components';
import * as Yup from 'yup';

const ButtonSeparator = styled.div({
    height: '12px',
});

const WelcomeMessageContainer = styled.div({
    marginTop: '-32px',
    paddingBottom: '32px',
    display: 'flex',
});
const RoboAvatarContainer = styled.div({
    flexShrink: 1,
    alignSelf: 'flex-end',
    marginRight: '12px',
    '* > svg': { width: '56px', height: '60px' },
});
const WelcomeMessage = styled.div((props) => ({
    width: '236px',
    height: '95px',
    padding: '12px 15px 12px 15px',
    textAlign: 'left',
    backgroundColor: 'white',
    border: '1px solid orange',
    borderColor: props.theme.colors.primary,
    position: 'relative',
    borderRadius: '10px',
    borderBottomLeftRadius: '0px',
    boxShadow: '0px 0px 20px #E9EEF2',
    p: {
        margin: '0',
        fontFamily: props.theme.fonts.bold,
        fontWeight: 500,
        fontStyle: 'normal',
        fontSize: '19.5px',
        lineHeight: '130%',
        color: 'black',
    },
    '&:before': {
        content: "''",
        position: 'absolute',
        width: '0',
        height: '0',
        bottom: '-1px',
        borderStyle: 'solid',
        left: '-14px',
        borderColor: `transparent transparent ${props.theme.colors.primary} transparent`,
        borderWidth: '0 0 14px 14px',
        zIndex: 1,
    },
    '&:after': {
        content: "''",
        position: 'absolute',
        width: '0',
        height: '0',
        bottom: '0px',
        borderStyle: 'solid',
        left: '-11px',
        borderColor: 'transparent transparent  #fff transparent',
        borderWidth: '0 0 12px 12px',
        zIndex: 1,
    },
}));

const EddyTravelsLogoContainer = styled.div({
    svg: { width: '44px', height: '44px' },
});

const { publicRuntimeConfig } = getConfig();

const getGoogleRedirectUrl = (parsedTrackingParams: string) => {
    const googleAppId = publicRuntimeConfig?.googleAppId;
    const oauthReturnUrl = publicRuntimeConfig?.rootUrl + '/login/oauth';
    return `https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=${googleAppId}&redirect_uri=${oauthReturnUrl}&scope=profile email&state=${encodeURIComponent(
        `${parsedTrackingParams}&auth_provider=google`
    )}`;
};

const getFacebookRedirectUrl = (parsedTrackingParams: string) => {
    const facebookAppId = publicRuntimeConfig?.facebookAppId;
    const facebookReturnUrl = publicRuntimeConfig?.rootUrl + '/login/oauth';
    return `https://www.facebook.com/v8.0/dialog/oauth?client_id=${facebookAppId}&redirect_uri=${facebookReturnUrl}&scope=public_profile,email&state=${encodeURIComponent(
        `${parsedTrackingParams}&auth_provider=facebook`
    )}`;
};

interface FormValues {
    email: string;
    tcAgreed: boolean;
    [key: string]: any;
}

enum SubmitStatus {
    SUBMITTING,
    FAILURE,
    SUCCESS,
}

const LoginPage: React.FC = () => {
    const router = useRouter();
    const isWidget = useStore((store) => store.isWidget);
    const theme = useTheme();
    const user = useUserState((state) => state.user);
    const login = useLogin();
    const [facebookUrl, setFacebookUrl] = useState('');
    const [googleUrl, setGoogleUrl] = useState('');
    useEffect(() => {
        // @ts-ignore
        const searchParams = new URLSearchParams(window.location.search);
        const queryParams = getQueryParams(searchParams);
        const parsedQueryParams = new URLSearchParams(queryParams).toString();
        setFacebookUrl(getFacebookRedirectUrl(parsedQueryParams));
        setGoogleUrl(getGoogleRedirectUrl(parsedQueryParams));

        if (!user) {
            login();
        } else if (!user.guest) {
            let path = '/';
            if (parsedQueryParams) {
                path += `?${parsedQueryParams}`;
            }
            if (searchParams.get('redirect_url')) {
                path = String(searchParams.get('redirect_url'));
            }
            router.push(path);
        }
    }, [router.query, user]);

    const [submitStatus, setSubmitStatus] = useState<SubmitStatus | null>(null);
    const emailInputRef = useRef<HTMLInputElement | null>(null);

    const form = useFormik({
        initialValues: {
            email: '',
            tcAgreed: false,
        },
        onSubmit: async (values) => {
            try {
                // @ts-ignore
                const queryParams = getQueryParams(new URLSearchParams(window.location.search));
                await api.requestChatActivation(values.email, queryParams);
                await router.push(`/success?email=${values.email}`);
                setSubmitStatus(SubmitStatus.SUCCESS);
                TagManager.dataLayer({
                    dataLayer: { event: GTM_EVENTS.SUBMITTED_EMAIL },
                });
            } catch (e) {
                setSubmitStatus(SubmitStatus.FAILURE);
            }
        },
        validationSchema: Yup.object<FormValues>().shape({
            email: Yup.string()
                .min(1)
                .email('⇧ Enter a valid email.')
                .required('⇧ Enter a valid email.'),
            tcAgreed: Yup.boolean().oneOf([true], '⇧ Check this box to proceed.'),
        }),
    });
    useEffect(() => {
        TagManager.dataLayer({
            dataLayer: { event: GTM_EVENTS.OPENED_REGISTRATION },
        });

        setTimeout(() => form.setFieldValue('tcAgreed', false));
    }, []);
    useEffect(() => {
        if (typeof router?.query?.email === 'string' && router?.query?.email !== '') {
            form.setFieldValue('email', router?.query?.email);
            // setFieldValue does not validate form after setting new value so a manual
            // setFieldTouch is used with a slight delay https://github.com/formik/formik/issues/2059
            setTimeout(() => form.setFieldTouched('email', true));
            router.replace(router.pathname, undefined, { shallow: true });
        }
    }, [router?.query?.email]);

    // focus input on TC agreement
    // also open keyboard on mobile devices
    useEffect(() => {
        if (form.values.tcAgreed && emailInputRef.current) {
            emailInputRef.current.focus();
        }
    }, [form.values.tcAgreed]);

    // making sure that form validation is trigger when email is valid
    // for some mobile device this does not trigger automatically
    useEffect(() => {
        if (!form.errors.email) {
            form.validateForm();
            if (form.values.email) {
                form.setFieldTouched('email', true);
            }
        }
    }, [form.values.email]);

    const formButtonDisabled =
        form.isSubmitting ||
        submitStatus === SubmitStatus.SUCCESS ||
        Object.keys(form.errors).length !== 0 ||
        Object.keys(form.touched).length === 0;

    return (
        <PageLayout
            headerImageHeight="112px"
            overflowY="auto"
            headerImage={
                <EddyTravelsLogoContainer>
                    <Logo height={44} />
                </EddyTravelsLogoContainer>
            }
        >
            <WelcomeMessageContainer>
                <RoboAvatarContainer>
                    <Avatar height={56} width={56} />
                </RoboAvatarContainer>
                <div>
                    <WelcomeMessage>
                        <p>The best travel deals just a step away!</p>
                        <p>🏨 🏖 ✈ 👇️</p>
                    </WelcomeMessage>
                </div>
            </WelcomeMessageContainer>
            <form onSubmit={form.handleSubmit}>
                <Checkbox
                    id="tcAgreed"
                    name="tcAgreed"
                    labelElement={<TermsLabel />}
                    touched={form.touched.tcAgreed}
                    error={form.errors.tcAgreed}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    checked={form.values.tcAgreed}
                    data-test="tc-agree-checkbox"
                />
                <TextInput
                    ref={emailInputRef}
                    id="email"
                    name="email"
                    type="email"
                    active={form.values.tcAgreed}
                    autoComplete="email"
                    touched={form.touched.email}
                    error={form.errors.email}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    value={form.values.email}
                    placeholder="Email"
                    data-test="email-input-box"
                />
                <FormButton
                    disabled={formButtonDisabled}
                    css={{ opacity: 1 }}
                    color={formButtonDisabled ? theme.colors.darkgrey : theme.colors.background}
                    onPress={form.submitForm}
                >
                    Continue
                </FormButton>
            </form>
            <ButtonSeparator />
            {submitStatus === SubmitStatus.FAILURE && <ActivationError />}

            <div css={{ fontSize: '16px', lineHeight: '19px', fontFamily: theme.fonts.default }}>
                or
            </div>
            <ButtonSeparator />
            <FacebookButton
                disabled={!form.values.tcAgreed}
                onClick={() => {
                    if (form.values.tcAgreed) {
                        return openLink(facebookUrl, isWidget ? '_blank' : '_self');
                    }
                    form.setTouched({ tcAgreed: true });
                }}
            />
            <ButtonSeparator />
            {isWebview(getUA) && isIOS ? null : (
                <GoogleButton
                    disabled={!form.values.tcAgreed}
                    onClick={() => {
                        if (form.values.tcAgreed) {
                            return openLink(googleUrl, isWidget ? '_blank' : '_self');
                        }
                        form.setTouched({ tcAgreed: true });
                    }}
                />
            )}
        </PageLayout>
    );
};

export default LoginPage;
