import { FormButton } from 'components/molecules/button/FormButton';
import React from 'react';
import styled from 'styled-components';

import { Google } from '../../icons/Google';

const GoogleButtonWrapper = styled.div({
    position: 'absolute',
    left: '28px',
});

interface Props {
    disabled: boolean;
    onClick?: (e: Event) => void;
}

export const GoogleButton = (props: Props) => {
    return (
        <FormButton
            height="50px"
            backgroundColor="#4285F4"
            disabled={props.disabled}
            onPress={props.onClick!}
        >
            <GoogleButtonWrapper>
                <Google />
            </GoogleButtonWrapper>
            Continue with Google
        </FormButton>
    );
};
