import React from 'react';
import styled from 'styled-components';

const TermsLabelWrapper = styled.div((props) => ({
    paddingLeft: '16px',
    paddingTop: '9px',
    paddingBottom: '5px',
    fontSize: '18px',
    lineHeight: '22px',

    fontFamily: props.theme.fonts.default,
    fontWeight: 'normal',
    color: props.theme.colors.text,
}));

const TermsLabelLink = styled.a((props) => ({
    fontSize: 18,
    fontFamily: props.theme.fonts.default,
    fontWeight: 500,
    lineHeight: '26px',
    textAlign: 'right',
    color: props.theme.colors.primary,
    textDecoration: 'none',
    '&:hover': {
        textDecoration: 'underline',
        cursor: 'pointer',
    },
}));

export const TermsLabel = () => {
    return (
        <TermsLabelWrapper>
            &nbsp;I agree to the&nbsp;
            <TermsLabelLink href="https://www.eddytravels.com/terms-of-service" target="_blank">
                Terms & Conditions
            </TermsLabelLink>
        </TermsLabelWrapper>
    );
};
