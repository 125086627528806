export const Google = () => {
    return (
        <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M17.5781 9.46094C17.5781 8.89844 17.5078 8.47656 17.4375 8.01953H9.14062V11.0078H14.0625C13.8867 12.3086 12.5859 14.7695 9.14062 14.7695C6.15234 14.7695 3.72656 12.3086 3.72656 9.25C3.72656 4.36328 9.49219 2.11328 12.5859 5.10156L14.9766 2.81641C13.4648 1.41016 11.4609 0.53125 9.14062 0.53125C4.28906 0.53125 0.421875 4.43359 0.421875 9.25C0.421875 14.1016 4.28906 17.9688 9.14062 17.9688C14.168 17.9688 17.5781 14.4531 17.5781 9.46094Z"
                fill="white"
            />
        </svg>
    );
};
