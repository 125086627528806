import React from 'react';
import styled from 'styled-components';

const ActivationErrorWrapper = styled.div((props) => ({
    fontFamily: props.theme.fonts.default,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 18,
    lineHeight: '22px',
    textAlign: 'center',
    marginTop: -10,
    padding: 10,
}));

export const ActivationError = () => (
    <ActivationErrorWrapper data-test="activation-error-message">
        Failed to send activation email, please try again later.
    </ActivationErrorWrapper>
);
